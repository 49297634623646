





















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import axios from "axios";
import { Expert, User, Field } from "@/types";

@Component({ components: { Loading, ConfirmDialog } })
export default class ExpertForm extends Vue {
  expert: Expert | null = null;
  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"],
  };

  fields: Field[] = [];

  formIsValid: boolean = false;

  formTitle = "";

  formFirstname = "";
  formLastname = "";
  formEmail = "";
  formPhone = "";
  formAddress = "";
  formZipcode = "";
  formCity = "";
  formCountry = "";
  formFieldId = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  statusDict = { pending: "en cours", refused: "refusé", processed: "traité" };

  get expertId(): string {
    const { expertId = "" } = this.$route.params;
    return expertId;
  }

  get isEditMode(): boolean {
    return this.expertId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  get user(): User {
    return this.$store.getters.getUser;
  }

  async mounted() {
    this.loading = true;

    this.vueAppAdminUrl = process.env.VUE_APP_ADMIN_URL;

    await this.fetchField();

    await this.fetchExpert();

    this.loading = false;
  }

  async fetchField() {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_ADMIN_URL}/admin/field`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (!data) {
        throw new Error("Unable to find field");
      }
      this.fields = data;
    } catch (_) {}
  }

  async fetchExpert() {
    if (!this.isEditMode) return;

    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_ADMIN_URL}/admin/expert/${this.expertId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (!data) {
        throw new Error("Unable to find expert");
      }
      this.expert = data;
      this.formTitle = `${data.firstname} ${data.lastname}`;

      this.formFirstname = this.expert!.firstname || "";
      this.formLastname = this.expert!.lastname || "";
      this.formEmail = this.expert!.email || "";
      this.formPhone = this.expert!.phone || "";
      this.formAddress = this.expert!.address || "";
      this.formZipcode = this.expert!.zipcode || "";
      this.formCity = this.expert!.city || "";
      this.formCountry = this.expert!.country || "";
      this.formFieldId = this.expert!.fieldId || "";

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
      this.$router.push({ name: "experts" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "experts" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    const expert = new Expert();
    expert.firstname = this.formFirstname;
    expert.lastname = this.formLastname;
    expert.email = this.formEmail;
    expert.phone = this.formPhone;
    expert.address = this.formAddress;
    expert.zipcode = this.formZipcode;
    expert.city = this.formCity;
    expert.country = this.formCountry;
    expert.fieldId = this.formFieldId;

    expert.companyId = this.user.company!.id;

    if (this.isEditMode) {
      // Expert on BDD
      const { data } = await axios.patch(
        `${process.env.VUE_APP_ADMIN_URL}/admin/expert/${this.expertId}`,
        expert,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
    } else {
      // Expert on BDD
      const { data } = await axios.post(
        `${process.env.VUE_APP_ADMIN_URL}/admin/expert`,
        expert,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
    }

    try {
      this.$store.commit("setSnackMessageWithColor", {
        message: "Expert informations was saved successfully",
        color: "success",
      });

      if (close) {
        this.$router.push({ name: "experts" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }

    this.loading = false;
  }

  handleOnDelete() {
    this.confirmDialogIsOpen = true;
  }

  handleOnCancel() {
    this.confirmDialogIsOpen = false;
  }

  async handleConfirmOnDelete() {
    this.loading = true;

    try {
      await axios.delete(
        `${process.env.VUE_APP_ADMIN_URL}/admin/expert/${this.expertId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      this.$store.commit("setSnackMessageWithColor", {
        message: "Expert was removed",
        color: "success",
      });

      this.$router.push({ name: "experts" });
      return;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }

    this.loading = false;
  }
}
