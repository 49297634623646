

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  @Prop() open!: boolean;

  @Prop() content!: string;

  @Prop() title!: string;

  defaultContent: string = "Are your sure to want to remove this element ?";

  defaultTitle: string = "Remove confirmation";

  dialog: boolean = false;

  @Watch("open", { immediate: true })
  watchOpen(value: boolean) {
    this.dialog = value;
  }

  @Watch("dialog", { immediate: true })
  watchDialog(value: boolean) {
    if (!value) this.$emit("onCancel");
  }

  handleOnConfirm() {
    this.$emit("onConfirm");
  }

  handleOnCancel() {
    this.$emit("onCancel");
  }
}
