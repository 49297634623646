

































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { Field } from "@/types";

@Component
export default class DisplayFields extends Vue {
  @Prop() fields!: Field[];

  @Prop() loading!: boolean;

  isLoading: boolean = false;

  options = JSON.parse(localStorage.getItem("list_field_option") ?? "{}");
  search = localStorage.getItem("list_field_search") ?? "";

  selected: any = [];

  /*pagination: any = {
    sortBy: "createdAt",
  };*/

  headers: any = [
    { text: "Nom", value: "name", sortable: false },
    { text: "Date de création", value: "createdAt" },
    { text: "Actions", value: "action", sortable: false },
  ];

  @Watch("loading", { immediate: true })
  handleLoadingChange(value: boolean) {
    this.isLoading = value;
    if (!this.loading) {
      this.search = JSON.parse(localStorage.getItem("list_field_search") ?? "");
      this.options = JSON.parse(
        localStorage.getItem("list_field_option") ?? "{}"
      );
    }
  }

  @Watch("options", { immediate: true })
  handleOptionsChange(value: any) {
    if (!this.loading) {
      const myOption = {
        itemsPerPage: value.itemsPerPage,
        page: value.page,
        sortBy: value.sortBy,
        sortDesc: value.sortDesc,
      };
      localStorage.setItem("list_field_option", JSON.stringify(myOption));
    }
  }

  @Watch("search", { immediate: true })
  handleSearchChange(value: string) {
    if (!this.loading) {
      localStorage.setItem("list_field_search", value);
    }
  }

  toggleAll() {
    if (this.selected.length) this.selected = [];
    else this.selected = this.fields.slice();
  }

  /*changeSort(column: string) {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }*/
}
