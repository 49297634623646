


































import { Expert } from "@/types";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class MailExpertDialog extends Vue {
  @Prop() open!: boolean;

  @Prop() experts!: Expert[];

  @Prop() estimateId!: string;

  expertId: string = "";
  message: string = "";

  dialog: boolean = false;

  get expertsForSelect() {
    return this.experts.map((e) => {
      return {
        id: e.id,
        name:
          `${e.firstname} ${e.lastname}` +
          (e.field ? ` (${e.field!.name})` : ""),
      };
    });
  }

  @Watch("open", { immediate: true })
  watchOpen(value: boolean) {
    this.dialog = value;
  }

  @Watch("dialog", { immediate: true })
  watchDialog(value: boolean) {
    if (!value) this.$emit("onCancel");
  }

  handleOnConfirm() {
    this.$emit("onConfirm", this.expertId, this.message);
  }

  handleOnCancel() {
    this.$emit("onCancel");
  }
}
