














import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class ImagePreview extends Vue {
  @Prop() imageUrl!: string | null;

  previewModalIsOpen: boolean = false;

  @Watch("imageUrl")
  watchOpen(value: string | null) {
    if (!value) return;
    this.previewModalIsOpen = true;
  }

  @Watch("previewModalIsOpen")
  watchPreviewModalIsOpen(value: boolean) {
    if (!value) this.$emit("close");
  }
}
