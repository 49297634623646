


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import EventBus from "@/components/shared/EventBus.vue";
import Breadcrumb from "@/components/shared/Breadcrumb.vue";
import { User } from "@/types";

@Component({ components: { Breadcrumb } })
export default class Header extends Vue {
  otherBreadcrumbItems: object[] = [];

  get route() {
    return this.$route;
  }

  get user(): User {
    return this.$store.getters.getUser;
  }

  @Watch("route", { immediate: true, deep: true })
  watchRoute() {
    this.otherBreadcrumbItems = [];
  }

  mounted() {
    EventBus.$on("handleAddToBreadcrumb", this.handleAddToBreadcrumb);
  }

  destroyed() {
    EventBus.$off("handleAddToBreadcrumb", this.handleAddToBreadcrumb);
  }

  handleToggleDrawer = () => {
    EventBus.$emit("handleToggleDrawer");
  };

  handleOnGoToDashboard() {
    this.$router.push({ name: "dashboard" });
  }

  handleAddToBreadcrumb(items: object[]) {
    this.otherBreadcrumbItems = items;
  }

  handleOnLogout() {
    localStorage.removeItem("user_token");

    this.$store.commit("setUser", null);
    this.$store.commit("setSnackMessageWithColor", {
      message: "Logout success",
      color: "success",
    });

    this.$router.push({ name: "auth" });
  }
}
