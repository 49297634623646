
















































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";

@Component
export default class Login extends Vue {
  loading: boolean = false;

  formIsValid: boolean = false;

  formLogin: string = "";

  formPassword: string = "";

  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
  };

  async mounted() {
    if (localStorage.getItem("user_token")) {
      try {
        await this.getMe();
      } catch (err) {
        localStorage.removeItem("user_token");

        this.$store.commit("setSnackMessageWithColor", {
          message: "Votre session a expiré, veuillez vous reconnecter",
          color: "error",
        });

        this.loading = false;
      }
    }
  }

  get form(): any {
    return this.$refs.form;
  }

  handleOnSubmit() {
    if (!this.form.validate()) return;
    this.login();
  }

  async login() {
    this.loading = true;

    try {
      const variables = {
        username: this.formLogin,
        password: this.formPassword,
      };

      const { data } = await axios.post(
        process.env.VUE_APP_ADMIN_URL + "/auth/login",
        variables
      );

      if (!data || !data.access_token) throw new Error("Wrong credentials");

      localStorage.setItem("user_token", data.access_token);

      await this.getMe();

      this.$store.commit("setSnackMessageWithColor", {
        message: "Login success",
        color: "success",
      });
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });

      localStorage.removeItem("user_token");
      this.loading = false;
    }
  }

  async getMe() {
    this.loading = true;

    const { data } = await axios.get(
      process.env.VUE_APP_ADMIN_URL + "/auth/me",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      }
    );

    if (!data) throw new Error("Wrong credentials");

    this.$store.commit("setUser", data);

    if (this.$route.query.redirect) {
      window.location.href = this.$route.query.redirect.toString();
      return;
    }

    this.$router.push({ name: "dashboard" });
  }
}
