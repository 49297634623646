var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.experts,"loading":_vm.isLoading,"search":_vm.search,"options":_vm.options},on:{"update:search":function($event){_vm.search=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(item.createdAt),"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.field.name)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit-expert', params: { expertId: item.id } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pen")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }