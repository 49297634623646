

































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import axios from "axios";
import { Field, User } from "@/types";

@Component({ components: { Loading, ConfirmDialog } })
export default class FIeldForm extends Vue {
  field: Field | null = null;
  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"],
  };

  formIsValid: boolean = false;

  formTitle = "";

  formName = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  vueAppAdminUrl: string = "";

  statusDict = { pending: "en cours", refused: "refusé", processed: "traité" };

  get fieldId(): string {
    const { fieldId = "" } = this.$route.params;
    return fieldId;
  }

  get isEditMode(): boolean {
    return this.fieldId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  get user(): User {
    return this.$store.getters.getUser;
  }

  async mounted() {
    this.loading = true;

    this.vueAppAdminUrl = process.env.VUE_APP_ADMIN_URL;

    await this.fetchField();

    this.loading = false;
  }

  async fetchField() {
    if (!this.isEditMode) return;

    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_ADMIN_URL}/admin/field/${this.fieldId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (!data) {
        throw new Error("Unable to find field");
      }
      this.field = data;
      this.formTitle = `${data.name}`;
      this.formName = data.name;

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
      this.$router.push({ name: "fields" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "fields" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    const field = new Field();
    field.name = this.formName;
    field.companyId = this.user.company!.id;

    if (this.isEditMode) {
      // Expert on BDD
      const { data } = await axios.patch(
        `${process.env.VUE_APP_ADMIN_URL}/admin/field/${this.fieldId}`,
        field,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
    } else {
      // Expert on BDD
      const { data } = await axios.post(
        `${process.env.VUE_APP_ADMIN_URL}/admin/field`,
        field,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
    }

    try {
      this.$store.commit("setSnackMessageWithColor", {
        message: "Field informations was saved successfully",
        color: "success",
      });

      if (close) {
        this.$router.push({ name: "fields" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }

    this.loading = false;
  }

  handleOnDelete() {
    this.confirmDialogIsOpen = true;
  }

  handleOnCancel() {
    this.confirmDialogIsOpen = false;
  }

  async handleConfirmOnDelete() {
    this.loading = true;

    try {
      await axios.delete(
        `${process.env.VUE_APP_ADMIN_URL}/admin/field/${this.fieldId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      this.$store.commit("setSnackMessageWithColor", {
        message: "Field was removed",
        color: "success",
      });

      this.$router.push({ name: "fields" });
      return;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }

    this.loading = false;
  }
}
