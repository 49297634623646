export class Picture {
  id?: string;
  filename?: string;
}

export class CatEstimate {
  id?: string;
  title?: string;
}

export class Company {
  id?: string;
  name?: string;
  color?: string;
  logo?: string;
  email?: string;
}

export class User {
  id?: string;
  username?: string;
  email?: string;
  company?: Company;
}

export class Estimate {
  id?: string;
  title?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  description?: string;
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
  catEstimate?: CatEstimate;
  company?: Company;
  expert?: Expert;
  expertContactedByMailAt?: Date;
}

export class Field {
  id?: string;
  name?: string;
  company?: Company;
  companyId?: string;
}
export class Expert {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  createdAt?: Date;
  updatedAt?: Date;
  field?: Field;
  fieldId?: string;
  company?: Company;
  companyId?: string;
}
