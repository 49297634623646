

























import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Estimate } from "@/types";

@Component
export default class Dashboard extends Vue {
  loading: boolean = false;

  estimates: Estimate[] = [];

  mounted() {
    this.fetchPendingEstimates();
  }

  async fetchPendingEstimates() {
    this.loading = true;

    try {
      const { data } = await axios.get(
        process.env.VUE_APP_ADMIN_URL +
          "/admin/estimate?filter=status||$eq||pending",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (!data) {
        throw new Error("Unable to find estimates");
      }

      this.estimates = data;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }
  }
}
