import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Auth/Login.vue";

import DefaultTemplate from "@/components/shared/DefaultTemplate.vue";

import Dashboard from "../components/Dashboard.vue";

import EstimateList from "../components/Estimate/EstimateList.vue";
import EstimateForm from "../components/Estimate/EstimateForm.vue";

import ExpertList from "../components/Expert/ExpertList.vue";
import ExpertForm from "../components/Expert/ExpertForm.vue";

import FieldList from "../components/Field/FieldList.vue";
import FieldForm from "../components/Field/FieldForm.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/auth", name: "auth", component: Login },
  {
    path: "/",
    name: "home",
    component: DefaultTemplate,
    redirect: "dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/estimates",
        name: "estimates",
        component: EstimateList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Estimate list" },
          ],
        },
      },
      {
        path: "/edit-estimate/:estimateId",
        name: "edit-estimate",
        component: EstimateForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Estimate list", route: "estimates" },
            { label: "Edit estimate" },
          ],
        },
      },
      {
        path: "/experts",
        name: "experts",
        component: ExpertList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Expert list" },
          ],
        },
      },
      {
        path: "/edit-expert/:expertId",
        name: "edit-expert",
        component: ExpertForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Expert list", route: "experts" },
            { label: "Edit expert" },
          ],
        },
      },
      {
        path: "/new-expert",
        name: "new-expert",
        component: ExpertForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Expert list", route: "experts" },
            { label: "New expert" },
          ],
        },
      },
      {
        path: "/fields",
        name: "fields",
        component: FieldList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Field list" },
          ],
        },
      },
      {
        path: "/edit-field/:fieldId",
        name: "edit-field",
        component: FieldForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Field list", route: "fields" },
            { label: "Edit field" },
          ],
        },
      },
      {
        path: "/new-field",
        name: "new-field",
        component: FieldForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Field list", route: "fields" },
            { label: "New field" },
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
