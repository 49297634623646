
















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { Estimate } from "@/types";
import axios from "axios";

@Component
export default class DisplayEstimates extends Vue {
  @Prop() estimates!: Estimate[];

  @Prop() loading!: boolean;

  isLoading: boolean = false;

  options = JSON.parse(localStorage.getItem("list_estimate_option") ?? "{}");
  search = localStorage.getItem("list_estimate_search") ?? "";

  selected: any = [];

  /*pagination: any = {
    sortBy: "createdAt",
  };*/

  headers: any = [
    { text: "Titre", value: "title" },
    { text: "Status", value: "status" },
    { text: "Catégorie", value: "categorie", sortable: false },
    { text: "Nom", value: "name", sortable: false },
    { text: "Prenom", value: "firstname", align: " d-none" },
    { text: "Nom", value: "lastname", align: " d-none" },
    { text: "Email", value: "email" },
    { text: "Expertise", value: "expert" },
    { text: "Date de création", value: "createdAt" },
    { text: "Actions", value: "action", sortable: false },
  ];

  statusDict = { pending: "en cours", refused: "refusé", processed: "traité" };

  @Watch("loading", { immediate: true })
  handleLoadingChange(value: boolean) {
    this.isLoading = value;
    if (!this.loading) {
      this.search = JSON.parse(
        localStorage.getItem("list_estimate_search") ?? ""
      );
      this.options = JSON.parse(
        localStorage.getItem("list_estimate_option") ?? "{}"
      );
    }
  }

  @Watch("options", { immediate: true })
  handleOptionsChange(value: any) {
    if (!this.loading) {
      const myOption = {
        itemsPerPage: value.itemsPerPage,
        page: value.page,
        sortBy: value.sortBy,
        sortDesc: value.sortDesc,
      };
      localStorage.setItem("list_estimate_option", JSON.stringify(myOption));
    }
  }

  @Watch("search", { immediate: true })
  handleSearchChange(value: string) {
    if (!this.loading) {
      localStorage.setItem("list_estimate_search", value);
    }
  }

  toggleAll() {
    if (this.selected.length) this.selected = [];
    else this.selected = this.estimates.slice();
  }

  /*changeSort(column: string) {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }*/

  async changeStatus(statusStr: string, id: string) {
    const status = Object.keys(this.statusDict)[
      Object.values(this.statusDict).findIndex((s) => s == statusStr)
    ];

    const { data } = await axios.put(
      `${process.env.VUE_APP_ADMIN_URL}/admin/estimate/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      }
    );
  }
}
