































import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Field } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayFields from "./DisplayFields.vue";

@Component({ components: { DisplayFields, Loading } })
export default class EstimateList extends Vue {
  loading: boolean = false;

  fields: Field[] = [];

  error: boolean = false;

  mounted() {
    this.fetchFields();
  }

  async fetchFields() {
    this.loading = true;

    try {
      const { data } = await axios.get(
        process.env.VUE_APP_ADMIN_URL + "/admin/field",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (!data) {
        throw new Error("Unable to find fields");
      }

      this.fields = data;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }
  handleAddClick() {
    this.$router.push({ name: "new-field" });
  }

  async handleRefreshData() {
    await this.fetchFields();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success",
    });
  }

  handleOnCreateNewField() {
    this.$router.push({ name: "create-field" });
  }
}
