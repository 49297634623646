var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"color":"primary-bg"}},[_c('v-btn',{attrs:{"icon":"","title":"Back to estimate list"},on:{"click":_vm.handleBackClick}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Estimation")])],1),_c('v-card',{staticClass:"mx-auto mt-5 pa-2",attrs:{"max-width":"50vw","loading":_vm.loading}},[_c('v-list-item',{attrs:{"two-three":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.estimate.createdAt),"DD/MM/YYYY HH:mm:ss"))+" ")]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.estimate.title)+" ("+_vm._s(_vm.estimate.catEstimate.title)+")")]),_c('v-list-item-subtitle',{staticClass:"headline mb-2"},[_vm._v(_vm._s(_vm.statusDict[_vm.estimate.status]))])],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.estimate.firstname)+" "+_vm._s(_vm.estimate.lastname)+" "),(_vm.estimate.email)?_c('div',[_c('a',{attrs:{"href":("mailto:" + (_vm.estimate.email))}},[_vm._v(_vm._s(_vm.estimate.email))])]):_vm._e(),(_vm.estimate.phone)?_c('div',[_c('a',{attrs:{"href":("tel:" + (_vm.estimate.phone))}},[_vm._v(_vm._s(_vm.estimate.phone))])]):_vm._e(),(_vm.estimate.address || _vm.estimate.zipcode || _vm.estimate.city)?_c('div',[_vm._v(" "+_vm._s(_vm.estimate.address)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.estimate.zipcode)+" "+_vm._s(_vm.estimate.city)+" ")]):_vm._e()]),_c('v-card-text',[_vm._v(_vm._s(_vm.estimate.description))]),(_vm.estimate.pictures.length > 0)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10","offset-sm":"1"}},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.estimate.pictures),function(picture){return _c('v-col',{key:picture.id,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2,"tile":""}},[_c('v-img',{staticClass:"grey lighten-2 pointer",attrs:{"src":(_vm.vueAppAdminUrl + "/picture/" + (picture.filename)),"lazy-src":'/img/lazy.jpg',"aspect-ratio":"1","title":"Show image"},on:{"click":function($event){return _vm.handleOnImageClick(
                            (_vm.vueAppAdminUrl + "/picture/" + (picture.filename))
                          )}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)})],1)}),1),_c('ImagePreview',{attrs:{"imageUrl":_vm.previewImageUrl},on:{"close":function($event){_vm.previewImageUrl = null}}})],1)],1)],1)],1)]:_vm._e(),(_vm.estimate.expertContactedByMailAt)?_c('v-card-text',[_vm._v(" Demande d'expertise envoyée le "+_vm._s(_vm._f("dateFormat")(new Date(_vm.estimate.expertContactedByMailAt),"DD/MM/YYYY à HH:mm:ss"))+" à "),_c('router-link',{attrs:{"to":{
          name: 'edit-expert',
          params: { expertId: _vm.estimate.expert.id },
        }}},[_vm._v(" "+_vm._s(_vm.estimate.expert.firstname)+" "+_vm._s(_vm.estimate.expert.lastname)+" ")])],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleOnMail}},[_c('v-icon',[_vm._v("mdi-email")]),_vm._v(" Transmettre à un expert ")],1),_c('v-spacer'),_c('v-btn',{on:{"click":_vm.handleBackClick}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Retour à la liste ")],1),(_vm.estimate.status != 'pending')?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.pendingEstimate}},[_vm._v("En cours")]):_vm._e(),(_vm.estimate.status != 'refused')?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.refuseEstimate}},[_vm._v(" Refusée ")]):_vm._e(),(_vm.estimate.status != 'processed')?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.processEstimate}},[_vm._v(" Traitée ")]):_vm._e()],1)],2),_c('ConfirmDialog',{attrs:{"open":_vm.confirmDialogIsOpen},on:{"onConfirm":_vm.handleConfirmOnDelete,"onCancel":_vm.handleOnCancel}}),_c('MailExpertDialog',{attrs:{"open":_vm.mailDialogIsOpen,"experts":_vm.experts,"estimateId":_vm.estimateId},on:{"onConfirm":_vm.handleOnConfirmMail,"onCancel":_vm.handleOnCancelMail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }