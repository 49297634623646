














































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import MailExpertDialog from "./MailExpertDialog.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import ImagePreview from "@/components/shared/ImagePreview.vue";

import axios from "axios";
import { Estimate, Picture, User, CatEstimate, Expert } from "@/types";

@Component({
  components: { Loading, ConfirmDialog, MailExpertDialog, ImagePreview },
})
export default class EstimateForm extends Vue {
  estimate: Estimate | null = null;
  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"],
  };

  experts: Expert[] = [];

  formIsValid: boolean = false;

  formTitle: string = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;
  mailDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  statusDict = { pending: "en cours", refused: "refusé", processed: "traité" };

  get estimateId(): string {
    const { estimateId = "" } = this.$route.params;
    return estimateId;
  }

  get isEditMode(): boolean {
    return this.estimateId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    this.vueAppAdminUrl = process.env.VUE_APP_ADMIN_URL;

    await this.fetchExperts();
    await this.fetchEstimate();

    this.loading = false;
  }

  async fetchEstimate() {
    if (!this.isEditMode) return;

    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_ADMIN_URL}/admin/estimate/${this.estimateId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (!data) {
        throw new Error("Unable to find estimate");
      }
      this.estimate = data;
      this.formTitle = data.title || "";

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
      this.$router.push({ name: "estimates" });
    }
  }

  async fetchExperts() {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_ADMIN_URL}/admin/expert`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (!data) {
        throw new Error("Unable to find expert");
      }
      this.experts = data;
    } catch (_) {}
  }

  async processEstimate() {
    const { data } = await axios.post(
      `${process.env.VUE_APP_ADMIN_URL}/admin/estimate/${this.estimateId}/process`,
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      }
    );

    this.estimate = data;

    this.$store.commit("setSnackMessageWithColor", {
      message: "Estimation notée comme traitée",
      color: "success",
    });
  }

  async pendingEstimate() {
    const { data } = await axios.post(
      `${process.env.VUE_APP_ADMIN_URL}/admin/estimate/${this.estimateId}/pending`,
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      }
    );

    this.estimate = data;

    this.$store.commit("setSnackMessageWithColor", {
      message: "Estimation notée comme en cours",
      color: "success",
    });
  }

  async refuseEstimate() {
    const { data } = await axios.post(
      `${process.env.VUE_APP_ADMIN_URL}/admin/estimate/${this.estimateId}/refuse`,
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      }
    );

    this.estimate = data;

    this.$store.commit("setSnackMessageWithColor", {
      message: "Estimation notée comme refusée",
      color: "success",
    });
  }

  handleBackClick() {
    this.$router.push({ name: "estimates" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      /*const variables = {
        manga: {
          id: this.isEditMode ? this.estimateId : undefined,
          title: this.formTitle
        }
      };

      const mutation =
        this.manga && this.mangaId ? MUTATION_EDIT_MANGA : MUTATION_ADD_MANGA;
      const { data } = await this.$apollo.mutate({ mutation, variables });

      if (this.isEditMode && (!data || !data.updateManga)) {
        throw new Error("Unable to edit manga");
      } else if (!this.isEditMode && (!data || !data.createManga)) {
        throw new Error("Unable to create manga");
      }*/

      this.$store.commit("setSnackMessageWithColor", {
        message: "Estimate informations was saved successfully",
        color: "success",
      });

      if (close) {
        this.$router.push({ name: "estimates" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }

    this.loading = false;
  }

  handleOnDelete() {
    this.confirmDialogIsOpen = true;
  }

  handleOnCancel() {
    this.confirmDialogIsOpen = false;
  }

  async handleOnConfirmMail(expertId: string, message: string) {
    // SendMail
    const { data } = await axios.post(
      `${process.env.VUE_APP_ADMIN_URL}/admin/estimate/${this.estimateId}/mail-to-expert`,
      { expertId, message },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      }
    );

    this.$store.commit("setSnackMessageWithColor", {
      message: "Mail envoyé",
      color: "success",
    });
    this.mailDialogIsOpen = false;
  }

  handleOnCancelMail() {
    this.mailDialogIsOpen = false;
  }

  handleOnMail() {
    this.mailDialogIsOpen = true;
  }

  async handleConfirmOnDelete() {
    this.loading = true;

    try {
      /*const { data } = await this.$apollo.mutate({
        mutation: MUTATION_REMOVE_MANGA,
        variables: { mangaId: this.mangaId }
      });

      if (!data || !data.removeManga) {
        throw new Error("Unable to remove manga");
      }*/

      this.$store.commit("setSnackMessageWithColor", {
        message: "Estimate was removed",
        color: "success",
      });

      this.$router.push({ name: "estimates" });
      return;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }

    this.loading = false;
  }

  getUrlFromFileInput = (file: any): any => {
    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener("load", (e) => {
        resolve({ url: fr.result, file });
      });
    });
  };

  handleOnImageClick(image: string) {
    this.previewImageUrl = image;
  }
}
