












import { Component, Vue, Prop } from "vue-property-decorator";

import Header from "./Header.vue";
import LeftMenu from "./LeftMenu.vue";
import { User } from "@/types";

@Component({ components: { Header, LeftMenu } })
export default class DefaultTemplate extends Vue {
  // eslint-disable-next-line
  get userToken(): string | null {
    return localStorage.getItem("user_token");
  }

  get user(): User {
    return this.$store.getters.getUser;
  }

  mounted() {
    if (!this.userToken || !this.user) {
      this.$router.push({
        name: "auth",
        query: { redirect: window.location.hash },
      });
    }
  }
}
