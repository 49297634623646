































import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Expert } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayExperts from "./DisplayExperts.vue";

@Component({ components: { DisplayExperts, Loading } })
export default class EstimateList extends Vue {
  loading: boolean = false;

  experts: Expert[] = [];

  error: boolean = false;

  mounted() {
    this.fetchExperts();
  }

  async fetchExperts() {
    this.loading = true;

    try {
      const { data } = await axios.get(
        process.env.VUE_APP_ADMIN_URL + "/admin/expert",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (!data) {
        throw new Error("Unable to find experts");
      }

      this.experts = data;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error",
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }
  handleAddClick() {
    this.$router.push({ name: "new-expert" });
  }

  async handleRefreshData() {
    await this.fetchExperts();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success",
    });
  }

  handleOnCreateNewExpert() {
    this.$router.push({ name: "create-expert" });
  }
}
